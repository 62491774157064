import React from 'react';
import AppletView from 'components/applet';
import { useAppConfig } from 'helper-hooks';

function YoteHome() {
  const { appHomeOwner, appHomeAppId } = useAppConfig();
  return <AppletView ownerName={appHomeOwner} appId={appHomeAppId} />;
}

export default YoteHome;
