import React from 'react';
import PropTypes from 'prop-types';
import UserNameForm from 'packages/forms/username';
import inDialog from '../../../in-dialog';

const BackdropProps = {
  style: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

function UsernameFormDialog({ user, auth }) {
  const UserHandleDialog = inDialog(UserNameForm);
  return (
    <div style={{ padding: 8 }}>
      {
        (user && (!user._meta || !user._meta.username))
        && (
        <UserHandleDialog
          onCloseRoute="/"
          user={user}
          auth={auth}
          BackdropProps={BackdropProps}
        />
        )
      }
    </div>
  );
}
UsernameFormDialog.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    _meta: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
  auth: PropTypes.shape({
    email: PropTypes.string,
  }),
};
UsernameFormDialog.defaultProps = {
  user: null,
  auth: { email: '' },
};
export default UsernameFormDialog;
