import React from 'react';
import getAppletByMetaPath from 'packages/firebase/firestore/get-applet-by-meta-path';
import getCache from 'packages/firebase/firestore/get-cache';
import Home from 'components/home';

const HomePage = function () {
  return <Home />;
};

HomePage.defaultProps = {
  showOptions: false,
};

HomePage.getInitialProps = async () => {
  if (typeof window !== 'undefined') {
    return {};
  }
  const ownerName = process.env.NEXT_PUBLIC_HOME_OWNERNAME || '';
  const appId = process.env.NEXT_PUBLIC_HOME_APP || '';
  if (!ownerName || !appId) {
    return {};
  }
  const applet = await getAppletByMetaPath({ ownerName, appId, appletId: appId });
  const cache = applet && await getCache(applet.id);
  return { cache };
};

export default HomePage;
