import { useState, useEffect } from 'react';
import loadAuth from '../../firebase/auth';

const useLiveAuth = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    let cleanup;
    (async () => {
      const firebaseAuth = await loadAuth;
      cleanup = firebaseAuth.onAuthStateChanged(setAuth);
    })();
    return () => { if (cleanup) cleanup(); };
  });

  return auth;
};

export default useLiveAuth;
