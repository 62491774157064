import React from 'react';
import { useAppletKeyFromPath } from 'packages/db/with-appletKey';
import Widgets from 'packages/view-widgets/widgets';
import { useAppConfig } from 'helper-hooks';

function getWidgetsConfig(id) {
  return [{
    key: id,
    view: 'list-card',
    list: {
      enabled: true,
      title: 'true',
      styles: {
        display: 'grid',
        gridGap: '4px',
        gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 1fr) )',
      },
    },
    viewProps: {
      elevation: 1,
    },
    sm: {
      list: {
        enabled: true,
        title: true,
        styles: {
          display: 'grid',
          gridGap: '8px',
          gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
        },
      },
      viewProps: {
        elevation: 1,
      },
      view: 'list-card',
    },
  }];
}

function LinkistHome() {
  const { appHomeOwner, appHomeAppId } = useAppConfig();
  const id = useAppletKeyFromPath({ ownerName: appHomeOwner, appId: appHomeAppId });
  return (
    <div style={{ padding: '16px 8px 8px 8px', gridArea: 'footer' }}>
      {id && <Widgets widgets={getWidgetsConfig(id)} />}
    </div>
  );
}

export default LinkistHome;
