import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Navigation from 'components/applet/navigation';
import useLiveAuth from 'packages/auth/use-live-auth';
import useLiveApplet from 'packages/db/use-live-applet';
import { useAppConfig } from 'helper-hooks';
import UsernameFormDialog from './username-form-dialog';
import LinkistHome from './linkist-home';
import YoteHome from './yote-home';

function HomeContentWithAuth(props) {
  const { auth } = props;
  const user = useLiveApplet(auth.uid);
  return (
    <>
      <UsernameFormDialog auth={auth} user={user} />
      <Navigation user={user} />
    </>
  );
}

HomeContentWithAuth.propTypes = {
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

function HomeContent() {
  const auth = useLiveAuth();
  const isLinkist = useAppConfig().appName.toLocaleLowerCase() === 'linkist';
  const hasAuth = !!auth && !!auth.uid;
  return (
    <>
      {isLinkist ? <LinkistHome /> : <YoteHome />}
      {hasAuth && <HomeContentWithAuth auth={auth} />}
    </>
  );
}

export default HomeContent;
