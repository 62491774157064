import React from 'react';
import Head from 'next/head';
import RedirectLinks from 'components/login/redirect-links';
import WidgetView from 'packages/view-widgets/widgets/widget';
import useAuth from 'packages/auth/use-auth';
import { useAppConfig } from 'helper-hooks';
import HomeContent from 'components/home/content';
import { NoSsr } from '@mui/material';

function HomeHeader() {
  const auth = useAuth();
  if (!auth || !auth.uid) {
    return <RedirectLinks />;
  }
  const widget = { key: auth.uid };
  return (
    <div style={{ gridArea: 'top' }}>
      <WidgetView widget={widget} />
    </div>
  );
}
function HomePage() {
  const { appName, appDescription } = useAppConfig();

  return (
    <>
      <Head>
        <title>{appName}</title>
        <meta name="description" content={appDescription} />
      </Head>
      <NoSsr>
        <HomeHeader />
        <HomeContent />
      </NoSsr>
    </>
  );
}

export default HomePage;
