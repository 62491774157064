import query from '../query';

type metaParams = {
  username?: string;
  appId?: string;
  appletId?: string;
  ownerName?: string;
};
async function getAppletByMetaPath(params: metaParams) {
  const {
    username, appId, appletId, ownerName,
  } = params;

  if (!username && !ownerName) {
    throw new Error('username or ownername is required');
  }
  if (username) {
    const result = await query('applets', [
      ['_meta._username', '==', username.toUpperCase()],
      ['_meta.private', '==', false],
    ]);
    return result[0];
  }
  if (ownerName && !appId) {
    const result = await query('applets', [
      ['_meta._ownerName', '==', ownerName.toUpperCase()],
      ['_meta.private', '==', false],
    ]);
    return result[0];
  }
  if (ownerName && appId && !appletId) {
    const result = await query('applets', [
      ['_meta._ownerName', '==', ownerName.toUpperCase()],
      ['_meta._appId', '==', appId.toUpperCase()],
      ['_meta._appletId', '==', appId.toUpperCase()],
      ['_meta.private', '==', false],
    ]);
    return result[0];
  }
  if (ownerName && appId && appletId) {
    const result = await query('applets', [
      ['_meta._ownerName', '==', ownerName.toUpperCase()],
      ['_meta._appId', '==', appId.toUpperCase()],
      ['_meta._appletId', '==', appletId.toUpperCase()],
      ['_meta.private', '==', false],
    ]);
    return result[0];
  }
  throw new Error('invalid params');
}

export default getAppletByMetaPath;
